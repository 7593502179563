<template>
  <div class="basket-price-summary">
    <telia-heading class="basket-price-summary__title" variant="title-100" tag="h2">
      {{ t("basketPriceSummary.summary") }}
    </telia-heading>
    <telia-row class="basket-price-summary__rows">
      <telia-col width="6" class="basket-price-summary__price-type">
        <telia-p>{{ t("basketPriceSummary.onetimePrice") }}</telia-p>
      </telia-col>
      <telia-col
        v-if="onetimePriceBeforeDiscount"
        width="6"
        class="basket-price-summary__price-amount"
        t-id="basket-price-summary__onetime-price-with-discount"
      >
        <telia-p
          t-id="basket-price-summary-onetime-price"
          class="basket-price-summary__discounted-price"
        >
          <span class="basket-price-summary__price">{{ formatPrice(onetimePrice) }}</span>
          kr
        </telia-p>
        <telia-p class="basket-price-summary__regular-price" variant="additional-100">
          {{
            t("basketPriceSummary.priceRegularOnetime", {
              price: formatPrice(onetimePriceBeforeDiscount),
            })
          }}
        </telia-p>
      </telia-col>
      <telia-col
        v-else
        width="6"
        class="basket-price-summary__price-amount"
        t-id="basket-price-summary__onetime-price-without-discount"
      >
        <telia-p t-id="basket-price-summary-onetime-price">
          <span class="basket-price-summary__price">{{ formatPrice(onetimePrice) }}</span>
          kr
        </telia-p>
      </telia-col>
    </telia-row>
    <telia-row class="basket-price-summary__rows">
      <telia-col width="6" class="basket-price-summary__price-type">
        <telia-p>{{ t("basketPriceSummary.recurringPrice") }}</telia-p>
      </telia-col>
      <telia-col
        v-if="recurringPriceBeforeDiscount"
        width="6"
        class="basket-price-summary__price-amount"
        t-id="basket-price-summary__recurring-price-with-discount"
      >
        <telia-p
          t-id="basket-price-summary-recurring-price"
          class="basket-price-summary__discounted-price"
          ><span class="basket-price-summary__price">{{ formatPrice(recurringPrice) }}</span>
          {{ t("basketPriceSummary.priceMonth") }}
        </telia-p>
        <telia-p class="basket-price-summary__regular-price" variant="additional-100">
          {{
            t("basketPriceSummary.priceRegularRecurring", {
              price: formatPrice(recurringPriceBeforeDiscount),
            })
          }}
        </telia-p>
      </telia-col>
      <telia-col
        v-else
        width="6"
        class="basket-price-summary__price-amount"
        t-id="basket-price-summary__recurring-price-without-discount"
      >
        <telia-p t-id="basket-price-summary-recurring-price">
          <span class="basket-price-summary__price">
            {{ formatPrice(recurringPrice) }}
          </span>
          {{ t("basketPriceSummary.priceMonth") }}
        </telia-p>
      </telia-col>
    </telia-row>

    <telia-p class="basket-price-summary__excluding-container" variant="additional-100">
      <span class="basket-price-summary__excluding">{{
        t("basketPriceSummary.excludingVAT")
      }}</span></telia-p
    >
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../locale";
import { currentLanguage } from "@telia/b2b-i18n";

export default defineComponent({
  name: "basket-price-summary",
  mixins: [translateMixin],
  async created() {
    await translateSetup();
  },
  props: {
    onetimePrice: { type: String, required: true },
    onetimePriceBeforeDiscount: { type: String },
    recurringPrice: { type: String, required: true },
    recurringPriceBeforeDiscount: { type: String },
  },
  methods: {
    formatPrice(amount) {
      return new Intl.NumberFormat(currentLanguage()).format(amount);
    },
  },
});
</script>

<style lang="scss">
@import "~@teliads/components/foundations/typography/tokens";
@import "~@teliads/components/foundations/colors/tokens";
@import "~@teliads/components/foundations/spacing/tokens";

.basket-price-summary {
  padding: $telia-spacing-8 0;
  margin-bottom: $telia-spacing-24;

  &__title {
    margin-bottom: $telia-spacing-16;
  }

  &__price {
    font-weight: $telia-typography-weight-bold;
  }

  &__excluding-container {
    margin-top: $telia-spacing-8;
  }

  &__excluding {
    color: var(--purpur-color-transparent-black-600);
  }

  &__price-type {
    text-align: left;
  }

  &__price-amount {
    text-align: right;
  }

  &__discounted-price {
    color: $telia-purple-500;
  }

  &__regular-price {
    color: var(--purpur-color-transparent-black-600);
  }

  &__rows {
    margin-bottom: $telia-spacing-8;
  }
}
</style>
