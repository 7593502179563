<template>
  <div class="basket-item-card">
    <div>
      <telia-row>
        <telia-col width="12">
          <slot name="header" />

          <div :id="`basket-item-card__${uniqueId}`">
            <transition name="content">
              <div t-id="basket-item-card__expanded-container" v-if="expanded">
                <slot name="content" />
              </div>
            </transition>
          </div>

          <telia-button
            t-id="basket-item-card__expand-toggle"
            :text="expanded ? t('basketItemCard.showLess') : t('basketItemCard.showMore')"
            :aria-expanded="expanded.toString()"
            :aria-controls="`basket-item-card__${uniqueId}`"
            variant="text"
            size="sm"
            :right-icon="JSON.stringify(showMoreIcon)"
            @click="expand()"
          >
          </telia-button>
        </telia-col>
      </telia-row>
    </div>
  </div>
</template>

<script>
import { translateMixin, translateSetup } from "../locale";
import { v4 } from "uuid";

export default {
  name: "basket-item-card",
  mixins: [translateMixin],
  props: {
    initialExpanded: { type: Boolean, default: false },
  },
  data() {
    return {
      expanded: false,
      uniqueId: v4(),
    };
  },
  async created() {
    await translateSetup();
    this.expanded = this.initialExpanded;
  },
  computed: {
    showMoreIcon() {
      return this.expanded
        ? { name: "chevron-up", size: "sm" }
        : { name: "chevron-down", size: "sm" };
    },
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/tokens";
@import "~@teliads/components/foundations/motion/tokens";

.basket-item-card {
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: $telia-spacing-16 0;
}

.content-enter-active {
  max-height: 9999px;
  transition: max-height $telia-duration-1500 $telia-ease-in,
    opacity $telia-duration-150 $telia-ease-in;
}

.content-leave-active {
  max-height: 9999px;
  transition: max-height $telia-duration-300 $telia-ease-out,
    opacity $telia-duration-150 $telia-ease-out;
}
.content-enter-from,
.content-leave-to {
  max-height: 0 !important;
  opacity: 0;
}
</style>
