<template>
  <div t-id="b2b-basket" class="b2b-basket">
    <div class="b2b-basket__container" v-if="!basketIsEmpty">
      <div class="b2b-broadband-basket-item-container">
        <b2b-deletable-item
          v-for="(item, index) in broadbandItems"
          :t-id="`deletable-item-${index}`"
          :key="item.id"
          :error="errorDeletingItem === item.id"
          :pendingDeletion="itemIsPending(item.id)"
          :itemId="item.id"
          @delete-item="deleteItemInBasket"
        >
          <BasketItemCard
            :t-id="`broadband-item-${index}`"
            :key="item.id"
            :initial-expanded="showBasketItemExpanded"
          >
            <template #header>
              <telia-heading
                class="b2b-broadband-basket-item-container__header"
                variant="title-100"
                tag="h2"
                >{{ item.name }}
              </telia-heading>
              <div class="b2b-broadband-basket-item-container__badge-container">
                <Badge
                  v-if="showCollectiveInformation(item)"
                  t-id="broadband-item-details__collective-badge"
                  variant="information"
                  :showIcon="false"
                  :allyLabel="t('broadbandItem.badge.collective.ally')"
                  >{{ t("broadbandItem.badge.collective.content") }}</Badge
                >
                <Badge
                  v-if="item.startDate && hasInvalidStartDate(item.id)"
                  :t-id="`broadband-item__badge--${item.id}`"
                  variant="warning"
                  :allyLabel="t('broadbandItem.badge.startDate.ally')"
                  >{{ t("broadbandItem.badge.startDate.content") }}
                </Badge>
              </div>
              <div class="b2b-basket__address">
                <telia-p t-id="b2b-broadband-basket-item-address__address" variant="paragraph-100"
                  >{{ item.installationAddress.fullAddress }}
                </telia-p>
              </div>
              <div :class="'b2b-basket__notification'" v-if="showCollectiveInformation(item)">
                <Notification
                  t-id="broadband-item-details__collective-notification"
                  :heading="t('broadbandItem.notification.collective')"
                />
              </div>
            </template>
            <template #content>
              <BroadbandItemDetails
                :t-id="`broadband-item-${item.id}`"
                :item="item"
                :invalid-start-date="hasInvalidStartDate(item.id)"
              />
            </template>
          </BasketItemCard>
        </b2b-deletable-item>
      </div>
      <div>
        <basket-price-summary
          :onetime-price="onetimePrice"
          :onetime-price-before-discount="onetimePriceBeforeDiscount"
          :recurring-price="recurringPrice"
          :recurring-price-before-discount="recurringPriceBeforeDiscount"
        />

        <telia-button
          v-if="!hideGoToCheckout"
          t-id="checkout-button"
          variant="primary"
          full-width="true"
          :disabled="itemIdsBeingRemoved.length > 0"
          @click="goToCheckout"
        >
          {{ t("basket.checkout") }}
        </telia-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "./locale";
import BasketPriceSummary from "./components/BasketPriceSummary";
import BasketItemCard from "./components/BasketItemCard";
import BroadbandItemDetails from "./components/BroadbandItemDetails.vue";
import { domEmit } from "./utils/customEmitHelper";
import {
  successOrFunnelFail,
  trackBroadbandProductCheckout,
  trackBroadbandProductRemoveFormCart,
} from "./helpers/ga-helper";
import "@telia/b2b-deletable-item";
import { deleteItemInBasket } from "./services/basket-management-service";
import { getIsLoggedIn } from "./services/logged-in-service";
import { currentLanguage } from "@telia/b2b-i18n";
import { navigateToUrl } from "single-spa";
import { AgreementType } from "@telia/b2b-ecommerce-tracking";
import { applyPureReactInVue } from "veaury";
import { Badge, Notification } from "@purpurds/purpur";

export default defineComponent({
  name: "b2b-basket",
  components: {
    BasketPriceSummary,
    BasketItemCard,
    BroadbandItemDetails,
    Badge: applyPureReactInVue(Badge),
    Notification: applyPureReactInVue(Notification),
  },
  mixins: [translateMixin],
  props: {
    basket: { type: Object, default: () => ({}) },
    scopeId: { type: String },
    tscid: { type: String },
    hideGoToCheckout: { type: Boolean, default: false },
  },
  data() {
    return {
      itemIdsBeingRemoved: [],
      errorDeletingItem: null,
      isLoggedIn: false,
    };
  },
  async created() {
    await Promise.all([translateSetup(), this.getLoggedInStatus()]);
  },
  computed: {
    basketIsEmpty() {
      return !this.basket?.broadbandItems?.length;
    },
    broadbandItems() {
      return this.basket?.broadbandItems;
    },
    checkoutLink() {
      return `/foretag/mybusiness/${this.scopeId}/bestall/${this.tscid}/kassa/${this.basket.id}`;
    },
    isOpenPages() {
      return !this.scopeId;
    },
    itemIdsWithInvalidStartDates() {
      return this.broadbandItems
        .filter(
          (item) =>
            item.startDate == null ||
            new Date(item.startDate).getTime() < new Date(item.estimatedDeliveryDate).getTime()
        )
        .map((filteredItem) => filteredItem.id);
    },
    onetimePrice() {
      return this.basket?.onetimePrice || "0";
    },
    onetimePriceBeforeDiscount() {
      return this.basket?.onetimePriceBeforeDiscount;
    },
    recurringPrice() {
      return this.basket?.recurringPrice || "0";
    },
    recurringPriceBeforeDiscount() {
      return this.basket?.recurringPriceBeforeDiscount;
    },
    showBasketItemExpanded() {
      return this.basket?.broadbandItems?.length === 1;
    },
  },
  methods: {
    async getLoggedInStatus() {
      try {
        this.isLoggedIn = await getIsLoggedIn();
      } catch {
        // Ignore error
      }
    },
    addToPendingList(itemId) {
      this.itemIdsBeingRemoved.push(itemId);
    },
    async deleteItemInBasket({ detail: [itemIdToBeRemoved] }) {
      this.addToPendingList(itemIdToBeRemoved);
      this.errorDeletingItem = null;
      trackBroadbandProductRemoveFormCart(
        this.basket,
        itemIdToBeRemoved,
        this.isOpenPages,
        this.isLoggedIn
      );
      domEmit(this.$el, "update-basket-pending", true);
      try {
        if (this.isOpenPages) {
          domEmit(this.$el, "update-basket", {});
          return;
        }
        const basket = await successOrFunnelFail(
          () =>
            deleteItemInBasket(
              this.scopeId,
              this.tscid,
              this.basket.id,
              itemIdToBeRemoved,
              currentLanguage().toUpperCase()
            ),
          {
            isLoggedIn: this.isLoggedIn,
            isOpenPages: this.isOpenPages,
            totalPrice: this.recurringPrice,
            errorMessage: "Could not remove item",
            agreementType: this.isOpenPages ? AgreementType.SA : AgreementType.NFA,
          }
        );
        domEmit(this.$el, "update-basket", basket);
      } catch (error) {
        this.errorDeletingItem = itemIdToBeRemoved;
      } finally {
        this.removeFromPendingList(itemIdToBeRemoved);
        domEmit(this.$el, "update-basket-pending", false);
      }
    },
    goToCheckout() {
      trackBroadbandProductCheckout(this.basket, this.isLoggedIn);
      navigateToUrl(this.checkoutLink);
    },
    hasInvalidStartDate(itemId) {
      return this.itemIdsWithInvalidStartDates.includes(itemId);
    },
    itemIsPending(itemId) {
      return this.itemIdsBeingRemoved.includes(itemId);
    },
    removeFromPendingList(itemId) {
      const index = this.itemIdsBeingRemoved.indexOf(itemId);
      if (index !== -1) {
        this.itemIdsBeingRemoved.splice(index, 1);
      }
    },
    showCollectiveInformation(item) {
      return item.fttxAgreementType === "KFÄ3";
    },
  },
});
</script>

<style lang="scss">
@import "@purpurds/purpur/styles";

.b2b-basket {
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__address {
    color: var(--purpur-color-functional-black);
    margin-top: var(--purpur-spacing-100);
    margin-bottom: var(--purpur-spacing-200);
  }

  &__notification {
    margin-bottom: var(--purpur-spacing-200);
  }
}

.b2b-broadband-basket-item-container {
  margin-bottom: var(--purpur-spacing-200);

  &__header {
    margin-bottom: var(--purpur-spacing-150);
  }

  &__badge-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--purpur-spacing-150);
  }
}
</style>
