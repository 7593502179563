import {
  trackBeginCheckout,
  trackRemoveFromCart,
  AgreementType,
  Salesflow,
  trackFunnelFail,
} from "@telia/b2b-ecommerce-tracking";
import {
  BROADBAND_BRAND,
  BROADBAND_CATEGORY,
  BROADBAND_CHECKOUT_START,
  BROADBAND_LISTNAME,
  BROADBAND_SUBCATEGORY,
  BROADBAND_VARIANT,
  BROADBAND_CUPON_CODE,
  BROADBAND_NEW,
} from "./ga-constants";

export function trackBroadbandProductCheckout(basket, isLoggedIn) {
  trackBeginCheckout({
    products: buildTrackProductCheckoutPayload(basket),
    listName: BROADBAND_LISTNAME,
    agreementType: AgreementType.NFA,
    stepNumber: "1",
    stepLabel: BROADBAND_CHECKOUT_START,
    salesflow: Salesflow.MB,
    totalPrice: basket.recurringPrice,
    isOpenPages: false,
    isLoggedIn,
  });
}

export function trackBroadbandProductRemoveFormCart(basket, productId, isOpenPages, isLoggedIn) {
  trackRemoveFromCart({
    product: buildTrackProductRemoveFromCartPayload(basket, productId),
    listName: BROADBAND_LISTNAME,
    agreementType: AgreementType.NFA,
    totalPrice: basket.recurringPrice,
    isOpenPages,
    isLoggedIn,
  });
}

function buildTrackProductCheckoutPayload(basket) {
  return basket.broadbandItems.map((product) => {
    return {
      id: product.productId,
      name: product.name,
      price: basket.recurringPrice,
      category: BROADBAND_CATEGORY,
      subCategory: BROADBAND_SUBCATEGORY,
      brand: BROADBAND_BRAND,
      quantity: "1",
      variant: BROADBAND_VARIANT,
      recurringPrice: basket.recurringPrice,
      onetimePrice: basket.onetimePrice,
      couponCode: BROADBAND_CUPON_CODE,
      newOrExtend: BROADBAND_NEW,
    };
  });
}

function buildTrackProductRemoveFromCartPayload(basket, productId) {
  const product = basket.broadbandItems.find((product) => product.id === productId);
  return {
    id: product.productId,
    name: product.name,
    price: basket.recurringPrice,
    category: BROADBAND_CATEGORY,
    subCategory: BROADBAND_SUBCATEGORY,
    brand: BROADBAND_BRAND,
    quantity: "1",
    variant: BROADBAND_VARIANT,
    recurringPrice: basket.recurringPrice,
    onetimePrice: basket.onetimePrice,
    couponCode: BROADBAND_CUPON_CODE,
    newOrExtend: BROADBAND_NEW,
  };
}
export async function successOrFunnelFail(restCall, funnelFailOptions) {
  try {
    return await restCall();
  } catch (e) {
    trackFunnelFail(funnelFailOptions);
    throw e;
  }
}
