<script setup lang="ts">
import { Ref, withDefaults } from "vue";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-vue";
import { useUnleashClientWithScopeId } from "@telia/b2b-unleash-client-vue3/dist/b2b-unleash-client-vue3.esm";
import B2bBasket from "./b2b-basket.ce.vue";

const client: Ref<UnleashClient | null> = useUnleashClientWithScopeId("b2b-basket");
interface Props {
  basket?: object;
  scopeId?: string;
  tscid?: string;
  hideGoToCheckout?: boolean;
}
withDefaults(defineProps<Props>(), {
  basket: () => ({}),
  scopeId: undefined,
  tscid: undefined,
  hideGoToCheckout: false,
});
</script>

<template>
  <FlagProvider v-if="client !== null" :unleash-client="client" :start-client="false">
    <B2bBasket
      :basket="basket"
      :scope-id="scopeId"
      :tscid="tscid"
      :hide-go-to-checkout="hideGoToCheckout"
    />
  </FlagProvider>
</template>

<style scoped lang="scss"></style>
