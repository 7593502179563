// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/cjs.js??clonedRuleSet-5.use[1]!../../../common/temp/node_modules/.pnpm/sass-loader@13.3.3_webpack@5.94.0/node_modules/sass-loader/dist/cjs.js!./main.scss");
if(content.__esModule) content = content.default;
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add CSS to Shadow Root
var add = require("!../../../common/temp/node_modules/.pnpm/vue-style-loader@4.1.3/node_modules/vue-style-loader/lib/addStylesShadow.js").default
module.exports.__inject__ = function (shadowRoot) {
  add("2003f640", content, shadowRoot)
};