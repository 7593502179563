import B2bBasket from "./b2b-basket.ce.vue";
import BasketPriceSummary from "./components/BasketPriceSummary.vue";
import BasketItemCard from "./components/BasketItemCard.vue";
import BroadbandItemDetails from "./components/BroadbandItemDetails.entry";
import ToggleProvider from "./toggle-provider.ce.vue";

const styles = mergeStylesOfComponents(
  B2bBasket,
  BasketPriceSummary,
  BasketItemCard,
  BroadbandItemDetails,
  ToggleProvider
);

ToggleProvider.styles = styles;

export default ToggleProvider;

function mergeStylesOfComponents(...components): string[] {
  return components.flatMap(({ styles }) => styles);
}
