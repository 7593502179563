<template>
  <div class="broadband-item-details">
    <telia-row>
      <telia-col width="12">
        <telia-p
          v-if="showShippingAddress"
          t-id="broadband-item-details__delivery-address"
          class="broadband-item-details__delivery-address"
          variant="paragraph-100"
          :class="{
            'broadband-item-details__delivery-address--extra-spacing': !item.serviceDescriptionUrl,
          }"
        >
          {{ `${t("broadbandItem.shippingAddress")}: ${item.shippingAddress.fullAddress}` }}
        </telia-p>
        <telia-link
          v-if="item.serviceDescriptionUrl"
          t-id="broadband-item-details__service-description-link"
          class="broadband-item-details__service-description-link"
          variant="standalone"
          :href="item.serviceDescriptionUrl"
          target="_blank"
          >{{ t("broadbandItem.serviceDescription") }}
          <telia-icon
            class="broadband-item-details__link-icon"
            name="external"
            slot="right"
            size="sm"
          />
        </telia-link>
      </telia-col>
    </telia-row>
    <telia-row v-for="productDetail in productDetails" :key="productDetail.label">
      <telia-col width="12">
        <div
          class="broadband-item-details__detail-wrapper"
          t-id="broadband-item-details__detail-wrapper"
        >
          <DetailIcon :src="productDetail.icon" />
          <telia-p class="broadband-item-details__detail-label">
            {{ productDetail.label }}
          </telia-p>
        </div>
      </telia-col>
    </telia-row>
    <telia-row v-for="childProduct in childProducts" :key="childProduct.label">
      <telia-col width="12">
        <div
          class="broadband-item-details__detail-wrapper"
          t-id="broadband-item-details__detail-wrapper"
        >
          <DetailIcon :src="childProduct.icon" />
          <telia-p class="broadband-item-details__detail-label">
            {{ childProduct.label }}
          </telia-p>
        </div>
      </telia-col>
    </telia-row>
    <telia-row>
      <telia-col width="12" class="broadband-item-details__start-date-container">
        <div class="broadband-item-details__start-date-wrapper">
          <telia-icon name="calendar" size="md" />
          <telia-p
            class="broadband-item-details__detail-label"
            t-id="broadband-item-details__start-date"
          >
            {{
              t("configurationSummary.estimatedDeliveryDate", {
                date: formatDate(startDate),
              })
            }}
          </telia-p>
        </div>
      </telia-col>
    </telia-row>

    <telia-row
      v-if="hasDiscounts"
      class="broadband-item-details__discount-container"
      t-id="broadband-item-details__discount-container"
    >
      <telia-col width="12">
        <ul>
          <li v-for="(discount, index) in item.discounts" :key="index">
            <telia-icon name="tag" />
            <telia-p variant="paragraph-100">
              <span class="broadband-item-details__discount-text">{{ discount.description }}</span>
            </telia-p>
          </li>
        </ul>
      </telia-col>
    </telia-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin, translateSetup } from "../locale";
import DetailIcon from "./detail-icon";
import { currentLanguage } from "@telia/b2b-i18n";

export default defineComponent({
  name: "broadband-item-details",
  mixins: [translateMixin],
  components: {
    DetailIcon,
  },
  props: {
    item: { type: Object, default: () => ({}) },
    invalidStartDate: { type: Boolean },
  },
  setup() {},
  async created() {
    await translateSetup(["mybusinessotpbrowse"]);
  },
  computed: {
    hasDiscounts() {
      return !!this.item.discounts?.length;
    },
    installationAndShippingAddressesEqual() {
      return this.item?.shippingAddress?.pointId === this.item?.installationAddress?.pointId;
    },
    shippingAddress() {
      return this.item?.shippingAddress?.fullAddress;
    },
    showShippingAddress() {
      return this.shippingAddress && !this.installationAndShippingAddressesEqual;
    },
    startDate() {
      return this.invalidStartDate ? this.item.estimatedDeliveryDate : this.item.startDate;
    },
    productDetails() {
      return this.item?.productDetails ?? [];
    },
    childProducts() {
      return this.item?.childProducts ?? [];
    },
  },
  methods: {
    formatDate(dateStr) {
      let date = new Date(dateStr);
      return date.toLocaleDateString(currentLanguage(), { day: "numeric", month: "long" });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/tokens";
@import "~@teliads/components/foundations/colors/tokens";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/breakpoints/mixins";
@import "~@teliads/components/foundations/typography/tokens";

.broadband-item-details {
  margin-bottom: $telia-spacing-16;

  &__detail-wrapper,
  &__start-date-wrapper {
    display: flex;
    align-items: center;
    padding: $telia-spacing-8 0;
  }

  &__start-date-wrapper {
    background-color: $telia-gray-50;
    padding: $telia-spacing-8;
    border-radius: $telia-border-radius-8;
    margin-top: $telia-spacing-16;
  }

  &__start-date-container {
    @include telia-breakpoint("sm") {
      padding-left: 0;
    }
    @include telia-breakpoint("md") {
      padding-left: $telia-spacing-4;
    }
  }

  &__service-description-link {
    margin: $telia-spacing-16 0;
  }

  &__detail-label {
    margin-left: $telia-spacing-8;
  }

  &__delivery-address {
    margin-top: $telia-spacing-12;

    &--extra-spacing {
      margin-bottom: $telia-spacing-20;
    }
  }

  &__link-icon {
    margin-left: $telia-spacing-8;
    padding-top: $telia-spacing-2;
  }

  &__discount-container {
    color: $telia-purple-500;

    ul {
      margin: $telia-spacing-16 0 $telia-spacing-8;
      padding: 0;
      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        padding: $telia-spacing-8 0;

        telia-icon {
          margin-right: $telia-spacing-8;
        }
      }
    }
  }

  &__discount-text {
    margin-bottom: $telia-spacing-8;
    font-weight: $telia-typography-weight-medium;
  }
}
</style>
