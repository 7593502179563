import { corpBasketManagement } from "@telia/b2b-rest-client";

export const deleteItemInBasket = async (scopeId, tscid, basketId, basketItemId, lang) => {
  return corpBasketManagement.BasketControllerService.deleteItemInBasket(
    scopeId,
    tscid,
    basketId,
    basketItemId,
    lang
  );
};
