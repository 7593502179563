import BroadbandItemDetails from "./BroadbandItemDetails.vue";
import DetailIcon from "./detail-icon.vue";

const styles = mergeStylesOfComponents(BroadbandItemDetails, DetailIcon);

BroadbandItemDetails.styles = styles;

export default BroadbandItemDetails;

function mergeStylesOfComponents(...components): string[] {
  return components.flatMap(({ styles }) => styles);
}
