<template>
  <div>
    <img v-if="src" class="detail-icon" :src="src" role="presentation" alt="" />
    <telia-icon v-else name="broadband" size="md" />
  </div>
</template>

<script>
export default {
  name: "detail-icon",
  props: {
    src: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.detail-icon {
  height: 2.4rem;
  width: 2.4rem;
}
</style>
